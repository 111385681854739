@if (isLoading) {
  <div class="center">
    <mat-card class="loading-card">
      <mat-card-content>
        <p>Preparing room...</p>
        <div class="loader"></div>
      </mat-card-content>
    </mat-card>
    
  </div>
}
<router-outlet (activate)="roomLoaded()"> </router-outlet>